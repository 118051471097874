import React, { useState, useEffect } from 'react';
import { skillRef } from '../database/CollectionRef';
import ErrorPage from '../pages/ErrorPage';
import FullPageLoader from './FullPageLoader';

export default function SkillsEditor(props) {

  // EXPERIENCES
  const [skills, setSkills] = useState([]);

  // FETCHING LOADINGS AND ERRORS
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  
  // EXPERIENCE PROPERTIES
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [editSklID, setEditSklID] = useState('');

  function submitHandler(e) {
    e.preventDefault();
    setSaveError('');
    setSaveLoading(true);
    const skl = {
      title,
      description,
      type
    };
    try {
      if(editSklID) {
        //update
        skillRef.doc(editSklID).update(skl);
      }
      else {
        //new one
        skillRef.doc().set(skl);
      }
      emptyForm();
    }catch (error) {
      setSaveError("Save error: check entered data.");
    } finally {
      setSaveLoading(false);   
    }
  }

  function emptyForm() {
    setTitle('');
    setDescription('');
    setType('');
  }

  function resetHandler() {
    setTitle('');
    setDescription('');
    setType('');
    setEditSklID(''); 
    setSaveError('');
  }

  function startEditHandler(skl){
    setTitle(skl.title);
    setDescription(skl.description);
    setType(skl.type);
    setEditSklID(skl.id);
  }

  function deleteHandler(skl){
    setDeleteError('');
    setDeleteLoading(true);
    try {
      skillRef.doc(skl.id).delete();
    } catch (error) {
      setSaveError("Delete error: try again.")
    } finally {
      setDeleteLoading(false);
    }    
  }

  function getSkills() {
    setLoading(true);
    try {
      skillRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({id: doc.id, ...data});
        });
        setSkills(items);
      });      
    } catch (error) { 
      setError('Error during experiences loading.')
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSkills();
  }, []);

  return (
    <>
    { loading 
      ? <FullPageLoader />
      : error 
      ? <ErrorPage message={error}/>
      : (
        <div className="table-form-container">
          <div className="table-container">
            <table>
              <tbody>
                <tr className="table-head">
                  <th>Title</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Actions</th>
                </tr>
                {skills.map((skl) => (
                  <tr key={skl.id}>
                    <td>{skl.title}</td>
                    <td>{skl.description}</td>
                    <td>{skl.type}</td>
                    <td>                   
                      <p className="action" onClick={()=>startEditHandler(skl)}>modifica </p>
                      <p className="action" onClick={()=>deleteHandler(skl)}>elimina</p>
                      {deleteLoading && <p>Loading...</p>}
                      {deleteError && <p className="error-text">{deleteError}</p>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form-container">
            <form className="form" onSubmit={submitHandler} onReset={resetHandler}>
              <h1 className="title">Editor</h1>
              <div className="form-field">
                <label htmlFor="title">Title</label>
                <input type="text" id="title" placeholder="Enter title" required value={title} onChange={e => setTitle(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="description">Description</label>
                <textarea rows="5" id="description" placeholder="Enter description" required value={description} onChange={e => setDescription(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="type">Type</label>
                <select id="type" required value={type} onChange={e => setType(e.target.value)}>
                  <option value="" disabled hidden>Select a type</option>
                  <option value="Organizational">Organizational</option>
                  <option value="Interpersonal">Interpersonal</option>
                  <option value="Technical">Technical</option>
                </select>
              </div>
              <div className="btn-container">
                <label />
                <button className="primary block" type="submit">Save</button>
                <label />
                <button className="secondary block" type="reset">Cancel</button>
              </div>
              {saveLoading && <p>Loading...</p>}
              {saveError && <p className="error-text">{saveError}</p>}
            </form>
          </div>
        </div>
      )
    }
    </>
  );
};