import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from "../utility/auth";
 
export default function ProtectedRoute({component: Component, ...rest}) {

  const { currentUser } = useAuth();
  return (
    <Route {...rest} render={props => {
      return currentUser
        ? <Component {...props} />
        : <Redirect to="/" />
    }} />  
  );
};