import React, { useState, useEffect} from 'react';
import '../style/about.scss';
import profileimage from '../assets/img/profile.png';
import { aboutRef } from '../database/CollectionRef';
import FullPageLoader from './FullPageLoader';
import ErrorPage from '../pages/ErrorPage';
import cvPdf from '../assets/pdf/CV-ONE-PAGE.pdf';

export default function About() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [about, setAbout] = useState([]);

  function getAboutInfo() {
    setLoading(true);
    try {
      aboutRef.get().then((item) => {
        const items = item.docs.map((doc) => doc.data());
        setAbout(items);
        setLoading(false);
      });
    } catch (error) {
      setError('Error during about info loading.');
    }
  }

  useEffect(() => {
    getAboutInfo()
  }, []); //array vuoto -> viene eseguito solo al caricamento del componente.

  return (
    <>
      { loading 
        ? <FullPageLoader />
        : error 
        ? <ErrorPage message={error}/>
        : (
          <div className="about container">
            <div className="col-left">
              <div className="about-img">
                <img src={profileimage} alt="img" />
              </div>
            </div>
            <div className="col-right">
              <h1 className="section-title">About <span>Me</span></h1>
              {
                about.map((abt, index)=>(
                  <span key={index}>
                    <h2>{abt.favWorkplace}</h2>
                    <p>{abt.description}</p>
                    <a href={cvPdf} className="cta" target="_blank" rel="noopener noreferrer" download="FedericoVulcano.pdf">Download CV</a>
                  </span>
                ))
              }
            </div>
          </div>
        )
      }
    </>
  );
};