import React from 'react';
import '../style/footer.scss';
import fbicon from '../assets/icon/facebook.png';
import igicon from '../assets/icon/instagram.png';
import lkicon from '../assets/icon/linkedin.png';

export default function Footer() {
  return (
    <div className="footer container">
      <div className="brand">
        <h1><span>F</span>ederico <span>V</span>ulcano</h1>
      </div>
      <h2>There is no reason to do something less than your best</h2>
      <div className="social-icon">
        <div className="social-item">
          <a href="https://www.facebook.com/federico.vulcano.9/" target="_blank" rel="noopener noreferrer"><img src={fbicon} alt="img"/></a>
        </div>
        <div className="social-item">
          <a href="https://www.instagram.com/vulcanof_72/?hl=it" target="_blank" rel="noopener noreferrer"><img src={igicon} alt="img"/></a>
        </div>
        <div className="social-item">
          <a href="https://www.linkedin.com/in/federico-vulcano-616346181/" target="_blank" rel="noopener noreferrer"><img src={lkicon} alt="img"/></a>
        </div>
      </div>
      <p>Copyright © 2021 Federico Vulcano. All rights reserved.</p>
    </div>
  );
};