export const startAnimation = () => {

  const hamburger = document.querySelector('.hamburger');
  const navLinks = document.querySelector('.nav-links');
  const links = document.querySelectorAll('.nav-links li');
  const header = document.querySelector('header');

  function toggleBubbleMenu() {
    if (window.innerWidth < 961) {
      navLinks.classList.toggle('open');
      links.forEach(link => {
        link.classList.toggle('fade');
      });
      //Burger animation
      hamburger.classList.toggle('toggle');
    }
  }

  function displayMenuOnResize() {
    if (window.innerWidth >= 961) {
      if(navLinks.classList.contains('open')){
        navLinks.classList.remove('open');
        links.forEach(link => {
          link.classList.remove('fade');
        });
        hamburger.classList.remove('toggle');
      }
    }
  }

  document.addEventListener('scroll', () => {
    var scroll_position = window.scrollY;
    if (scroll_position > 250) {
      header.style.backgroundColor = "#29323c";
    }
    else {
      header.style.backgroundColor = "transparent";
    }
  });

  hamburger.addEventListener('click', toggleBubbleMenu);

  links.forEach(link => {
    link.addEventListener('click', toggleBubbleMenu);
  });

  window.addEventListener('resize', displayMenuOnResize);
  
}