import React, { useEffect, useState } from 'react';
import '../style/skills.scss';
import skillicon from '../assets/icon/terminal.png';
import { skillRef } from '../database/CollectionRef';
import FullPageLoader from './FullPageLoader';
import ErrorPage from '../pages/ErrorPage';

export default function Skills() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [skills, setSkills] = useState([]);

  function getSkills() {
    setLoading(true);
    try {
      skillRef.get().then((item) => {
        const items = item.docs.map((doc) => doc.data());
        setSkills(items);
        setLoading(false);
      });
    } catch (error) {
      setError('Error during contacts loading.');
    }
  }

  useEffect(() => {
    getSkills()
  }, []); //array vuoto -> viene eseguito solo al caricamento del componente.

  return (
    <>
      { loading 
        ? <FullPageLoader />
        : error 
        ? <ErrorPage message={error}/>
        : (
          <div className="skills container">
            <div className="skills-header">
              <h1 className="section-title">Sk<span>i</span>lls</h1>
              <p>During my working career I had the opportunity to improve my soft and technical skills. Here is a summary.</p>
            </div>
            <div className="skills-cards">
              {
                skills.map( (skl, index) => (
                  <div className="skill-item" key={index}>
                    <div className="icon">
                      <img src={skillicon} alt="img" />
                    </div>
                    <h2>{skl.title}</h2>
                    <p>{skl.description}</p>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </>
  );
};