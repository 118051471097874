import React, { useState, useEffect } from 'react';
import { experienceRef } from '../database/CollectionRef';
import ErrorPage from '../pages/ErrorPage';
import FullPageLoader from './FullPageLoader';

export default function ExperienceEditor(props) {

  // EXPERIENCES
  const [experiences, setExperiences] = useState([]);

  // FETCHING LOADINGS AND ERRORS
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  
  // EXPERIENCE PROPERTIES
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editExpID, setEditExpID] = useState('');

  function submitHandler(e) {
    e.preventDefault();
    setSaveError('');
    setSaveLoading(true);
    const exp = {
      title,
      description,
      type,
      startDate,
      endDate
    };
    try {
      if(editExpID) {
        //update
        experienceRef.doc(editExpID).update(exp);
      }
      else {
        //new one
        experienceRef.doc().set(exp);
      }
      emptyForm();
    }catch (error) {
      setSaveError("Save error: check entered data.");
    } finally {
      setSaveLoading(false);   
    }
  }

  function emptyForm() {
    setTitle('');
    setDescription('');
    setType('');
    setStartDate('');
    setEndDate('');
  }

  function resetHandler() {
    setTitle('');
    setDescription('');
    setType('');
    setStartDate('');
    setEndDate('');
    setEditExpID(''); 
    setSaveError('');
  }

  function startEditHandler(exp){
    setTitle(exp.title);
    setDescription(exp.description);
    setType(exp.type);
    setStartDate(exp.startDate);
    setEndDate(exp.endDate);
    setEditExpID(exp.id);
  }

  function deleteHandler(exp){
    setDeleteError('');
    setDeleteLoading(true);
    try {
      experienceRef.doc(exp.id).delete();
    } catch (error) {
      setSaveError("Delete error: try again.")
    } finally {
      setDeleteLoading(false);
    }    
  }

  function getExperiences() {
    setLoading(true);
    try {
      experienceRef.orderBy('startDate', 'desc')
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({id: doc.id, ...data});
        });
        setExperiences(items);
      });      
    } catch (error) { 
      setError('Error during experiences loading.')
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getExperiences();
  }, []);

  return (
    <>
    { loading 
      ? <FullPageLoader />
      : error 
      ? <ErrorPage message={error}/>
      : (
        <div className="table-form-container">
          <div className="table-container">
            <table>
              <tbody>
                <tr className="table-head">
                  <th>Title</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Actions</th>
                </tr>
                {experiences.map((exp, index) => (
                  <tr key={exp.id}>
                    <td>{exp.title}</td>
                    <td>{exp.description}</td>
                    <td>{exp.type}</td>
                    <td>{exp.startDate}</td>
                    <td>{exp.endDate}</td>
                    <td>                   
                      <p className="action" onClick={()=>startEditHandler(exp)}>modifica </p>
                      <p className="action" onClick={()=>deleteHandler(exp)}>elimina</p>
                      {deleteLoading && <p>Loading...</p>}
                      {deleteError && <p className="error-text">{deleteError}</p>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form-container">
            <form className="form" onSubmit={submitHandler} onReset={resetHandler}>
              <h1 className="title">Editor</h1>
              <div className="form-field">
                <label htmlFor="title">Title</label>
                <input type="text" id="title" placeholder="Enter title" required value={title} onChange={e => setTitle(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="description">Description</label>
                <textarea rows="5" id="description" placeholder="Enter description" required value={description} onChange={e => setDescription(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="type">Type</label>
                <select id="type" required value={type} onChange={e => setType(e.target.value)}>
                  <option value="" disabled hidden>Select a type</option>
                  <option value="edu">Educational</option>
                  <option value="work">Work</option>
                </select>
              </div>
              <div className="form-field">
                <label htmlFor="start-date">Start date</label>
                <input type="month" id="start-date" placeholder="Enter start date" required value={startDate} onChange={e => setStartDate(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="end-date">End date</label>
                <input type="month" id="end-date" placeholder="Enter end date" value={endDate} onChange={e => setEndDate(e.target.value)} />
              </div>
              <div className="btn-container">
                <label />
                <button className="primary block" type="submit">Save</button>
                <label />
                <button className="secondary block" type="reset">Cancel</button>
              </div>
              {saveLoading && <p>Loading...</p>}
              {saveError && <p className="error-text">{saveError}</p>}
            </form>
          </div>
        </div>
      )
    }
    </>
  );
};