import firebase from '../firebase';
import 'firebase/analytics';

let analytics;

export const initTracking = () => {
    analytics = firebase.analytics();
}

export const trackPageView = (pageName) => {
    analytics.logEvent('page_view_'+pageName, {pageName});
}

export const trackChangeLanguage = (language) => {
    analytics.logEvent('change_language_'+language, {language});
}

export const trackSocialLinkClicked = (social) => {
    analytics.logEvent('social_link_clicked_'+social, {social});
}

export const trackDownloadCV = (cvVersion) => {
    analytics.logEvent('download_cv_'+cvVersion, {cvVersion});
}