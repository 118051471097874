import React, { useState } from 'react';
import { useAuth } from '../utility/auth';
import { Link } from 'react-router-dom';
import '../style/admin.scss'

export default function ForgotPasswordPage(props) {

  const [email, setEmail] = useState('');
  const { resetPassword } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  async function submitHandler(e) {
    e.preventDefault();

    try {
      setMessage('')
      setError('');
      setLoading(true);
      await resetPassword(email);
      setMessage("Check your inbox for further instructions");
      setLoading(false);
    } catch (error) {
      const errorMessage = "Failed to reset password.";
      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div>
      <section>
        <div className="container">
          <div className="form-container">
            <form className="form" onSubmit={submitHandler}>
              <h1>Reset Password</h1>
              <div className="form-field">
                <label htmlFor="email">Email address</label>
                <input type="email" id="email" placeholder="Enter email" required onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="btn-container">
                <label />
                <button className="primary block" type="submit">Reset</button>
              </div>
              {loading && <p>Loading...</p>}
              {error && <p>{error}</p>}
              {message && <p>{message}</p>}
            </form>
            <div className="outer-link">
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};