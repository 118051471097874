import React from 'react';
import '../style/hero.scss';
import cvPdf from '../assets/pdf/CV-ONE-PAGE.pdf';

export default function Hero() {
  return (
    <div className="hero container">
      <div>
        <h1>Welcome everybody, <span></span></h1>
        <h1>thanks to visit<span></span></h1>
        <h1>my personal portfolio<span></span></h1>
        <a href={cvPdf} type="button" className="cta" target="_blank" rel="noopener noreferrer" download="FedericoVulcano.pdf">Download CV</a>
      </div>
    </div>
  );
};