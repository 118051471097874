import React, { useState } from 'react';
import { useHistory } from 'react-router';
import '../style/navbar.scss';
import { useAuth } from '../utility/auth';

export default function NavBarAdmin() {

  const [error, setError] = useState('');
  const { logout } = useAuth();
  const history = useHistory();

  async function logoutHandler () {
    setError('');
    try {
      await logout();
      history.push("/");
    } catch {
      setError("Failed");
    }
  }

  return (
    <nav>
      <div className="brand">
        <a href="#top">
          <h1><span>F</span>ederico <span>V</span>ulcano</h1>
        </a>
      </div>
      <div className="hamburger">
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
      </div>
      <ul className="nav-links">
        <li><a data-after="Home" href="#top">Home</a></li>
        <li><a data-after="Experience" href="#experience">Experience</a></li>
        <li><a data-after="Skills" href="#skill">Skills</a></li>
        <li><a data-after="About" href="#about">About</a></li>
        <li><a data-after="Contact" href="#contact">Contact</a></li>
        <li><a data-after="Messages" href="#messages">Messages</a></li>
        <li>
          <a href="#top" onClick={logoutHandler}>Logout</a>
          {error && <p className="error-test">{error}</p>}
        </li>
        <div className="bubble"></div>
      </ul>
    </nav>
  );
};