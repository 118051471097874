import React, { useState } from 'react';
import { useAuth } from '../utility/auth';
import { Link, useHistory } from 'react-router-dom';
import '../style/admin.scss'

export default function LoginPage(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function submitHandler(e) {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await login(email, password);
      history.push("/admin");
    } catch (error) {
      const errorMessage = "Failed to sign in";
      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div>
      <section>
        <div className="container">
          <div className="form-container">
            <form className="form" onSubmit={submitHandler}>
              <h1>Login</h1>
              <div className="form-field">
                <label htmlFor="email">Email address</label>
                <input type="email" id="email" placeholder="Enter email" required onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" placeholder="Enter password" required onChange={e => setPassword(e.target.value)} />
              </div>
              <div className="btn-container">
                <label />
                <button className="primary block" type="submit">Login</button>
              </div>
              {loading && <p>Loading...</p>}
              {error && <p className="error-text">{error}</p>}
            </form>
            <div className="outer-link">
              <Link to="/forgot-password" className="link">Password dimenticata?</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};