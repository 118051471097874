import React, { useState, useEffect } from 'react';
import { contactRef } from '../database/CollectionRef';
import ErrorPage from '../pages/ErrorPage';
import FullPageLoader from './FullPageLoader';

export default function ContactEditor(props) {

  // CONTACT
  const [contacts, setContacts] = useState([]);

  // FETCHING LOADINGS AND ERRORS
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  
  // EXPERIENCE PROPERTIES
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [contact, setContact] = useState('');
  const [editContactID, setEditContactID] = useState('');

  function submitHandler(e) {
    e.preventDefault();
    setSaveError('');
    setSaveLoading(true);
    const cntc = {
      type,
      title,
      contact
    };
    try {
      if(editContactID) {
        //update
        contactRef.doc(editContactID).update(cntc);
      }
      else {
        //new one
        contactRef.doc().set(cntc);
      }
      emptyForm();
    }catch (error) {
      setSaveError("Save error: check entered data.");
    } finally {
      setSaveLoading(false);
    }
  }

  function emptyForm() {
    setType('');
    setTitle('');
    setContact('');
  }

  function resetHandler() {
    setType('');
    setTitle('');
    setContact('');
    setEditContactID(''); 
    setSaveError('');
  }

  function startEditHandler(cntc){
    setType(cntc.type);
    setTitle(cntc.title);
    setContact(cntc.contact);
    setEditContactID(cntc.id);
  }

  function deleteHandler(cntc){
    setDeleteError('');
    setDeleteLoading(true);
    try {
      contactRef.doc(cntc.id).delete();
    } catch (error) {
      setSaveError("Delete error: try again.")
    } finally {
      setDeleteLoading(false);
    }    
  }

  function getContacts() {
    setLoading(true);
    try {
      contactRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({id: doc.id, ...data});
        });
        setContacts(items);
      });      
    } catch (error) { 
      setError('Error during contacts loading.')
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <>
    { loading 
      ? <FullPageLoader />
      : error 
      ? <ErrorPage message={error}/>
      : (
        <div className="table-form-container">
          <div className="table-container">
            <table>
              <tbody>
                <tr className="table-head">
                  <th>Type</th>
                  <th>Title</th>
                  <th>Contact</th>
                  <th>Actions</th>
                </tr>
                {contacts.map((cntc) => (
                  <tr key={cntc.id}>
                    <td>{cntc.type}</td>
                    <td>{cntc.title}</td>
                    <td>{cntc.contact}</td>
                    <td>                   
                      <p className="action" onClick={()=>startEditHandler(cntc)}>modifica </p>
                      <p className="action" onClick={()=>deleteHandler(cntc)}>elimina</p>
                      {deleteLoading && <p>Loading...</p>}
                      {deleteError && <p className="error-text">{deleteError}</p>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form-container">
            <form className="form" onSubmit={submitHandler} onReset={resetHandler}>
              <h1 className="title">Editor</h1>
              <div className="form-field">
                <label htmlFor="type">Type</label>
                <select id="type" required value={type} onChange={e => setType(e.target.value)}>
                  <option value="" disabled hidden>Select a type</option>
                  <option value="email">Email</option>
                  <option value="fb">Facebook</option>
                  <option value="ig">Instagram</option>
                  <option value="lk">Linkedin</option>
                  <option value="phone">Phone</option>
                </select>
              </div>
              <div className="form-field">
                <label htmlFor="title">Title</label>
                <input type="text" id="title" placeholder="Enter title" required value={title} onChange={e => setTitle(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="contact">Contact</label>
                <input type="text" id="contact" placeholder="Enter contact info" required value={contact} onChange={e => setContact(e.target.value)} />
              </div>
              <div className="btn-container">
                <label />
                <button className="primary block" type="submit">Save</button>
                <label />
                <button className="secondary block" type="reset">Cancel</button>
              </div>
              {saveLoading && <p>Loading...</p>}
              {saveError && <p className="error-text">{saveError}</p>}
            </form>
          </div>
        </div>
      )
    }
    </>
  );
};