import React, {useEffect } from 'react';
import * as animations from '../animations/animations';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Experience from '../components/Experience';
import Skills from '../components/Skills';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

export default function LandingPage() {

  useEffect(() => {
    animations.startAnimation();
  }, []); //array vuoto -> viene eseguito solo al caricamento del componente.

  return (
    <div>

      {/* Header [start] */}
      <header>
        <NavBar />
      </header>
      {/* Header [end] */}

      {/* Hero section [start] */}
      <section id="hero">
        <Hero />
      </section>
      {/* Hero section [end] */}

      {/* Experience container [start] */}
      <section id="experiences">
        <Experience />
      </section>
      {/* Experience container [end] */}

      {/* Skills section [start] */}
      <section id="skills">
        <Skills />
      </section>
      {/* Skills section [end] */}

      {/* About me [start] */}
      <section id="about">
        <About />
      </section>
      {/* About me [end] */}

      {/* Contact section [start] */}
      <section id="contact">
        <Contact />
      </section>
      {/* Contact section [end] */}

      {/* Footer [start] */}
      <section id="footer">
        <Footer />
      </section>
      {/* Footer [end] */}
    </div>
  );
};