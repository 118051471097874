import React, { useState, useEffect } from 'react';
import { aboutRef } from '../database/CollectionRef';
import ErrorPage from '../pages/ErrorPage';
import FullPageLoader from './FullPageLoader';

export default function AboutEditor(props) {

  // EXPERIENCES
  const [about, setAbout] = useState([]);

  // FETCHING LOADINGS AND ERRORS
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  
  // EXPERIENCE PROPERTIES
  const [favWorkplace, setFavWorkplace] = useState('');
  const [description, setDescription] = useState('');
  const [editAboutID, setEditAboutID] = useState('');

  function submitHandler(e) {
    e.preventDefault();
    setSaveError('');
    setSaveLoading(true);
    const abt = {
      favWorkplace,
      description
    };
    try {
      if(editAboutID) {
        //update
        aboutRef.doc(editAboutID).update(abt);
      }
      else {
        //new one
        aboutRef.doc().set(abt);
      }
      emptyForm();
    }catch (error) {
      setSaveError("Save error: check entered data.");
    } finally {
      setSaveLoading(false);   
    }
  }

  function emptyForm() {
    setFavWorkplace('');
    setDescription('');
  }

  function resetHandler() {
    setFavWorkplace('');
    setDescription('');
    setEditAboutID(''); 
    setSaveError('');
  }

  function startEditHandler(abt){
    setFavWorkplace(abt.favWorkplace);
    setDescription(abt.description);
    setEditAboutID(abt.id);
  }

  function deleteHandler(abt){
    setDeleteError('');
    setDeleteLoading(true);
    try {
      aboutRef.doc(abt.id).delete();
    } catch (error) {
      setSaveError("Delete error: try again.")
    } finally {
      setDeleteLoading(false);
    }    
  }

  function getAboutInfo() {
    setLoading(true);
    try {
      aboutRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({id: doc.id, ...data});
        });
        setAbout(items);
      });      
    } catch (error) { 
      setError('Error during about info loading.')
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAboutInfo();
  }, []);

  return (
    <>
    { loading 
      ? <FullPageLoader />
      : error 
      ? <ErrorPage message={error}/>
      : (
        <div className="table-form-container">
          <div className="table-container">
            <table>
              <tbody>
                <tr className="table-head">
                  <th>Favourite workplace</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
                {about.map((abt) => (
                  <tr key={abt.id}>
                    <td>{abt.favWorkplace}</td>
                    <td>{abt.description}</td>
                    <td>                   
                      <p className="action" onClick={()=>startEditHandler(abt)}>modifica </p>
                      <p className="action" onClick={()=>deleteHandler(abt)}>elimina</p>
                      {deleteLoading && <p>Loading...</p>}
                      {deleteError && <p className="error-text">{deleteError}</p>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form-container">
            <form className="form" onSubmit={submitHandler} onReset={resetHandler}>
              <h1 className="title">Editor</h1>
              <span className="note">NOTICE: Only one row is displayed in public page.</span>
              <div className="form-field">
                <label htmlFor="title">Favourite Workplace</label>
                <input type="text" id="title" placeholder="Enter favourite workplace" required value={favWorkplace} onChange={e => setFavWorkplace(e.target.value)} />
              </div>
              <div className="form-field">
                <label htmlFor="description">Description</label>
                <textarea rows="5" id="description" placeholder="Enter description" required value={description} onChange={e => setDescription(e.target.value)} />
              </div>
              <div className="btn-container">
                <label />
                <button className="primary block" type="submit">Save</button>
                <label />
                <button className="secondary block" type="reset">Cancel</button>
              </div>
              {saveLoading && <p>Loading...</p>}
              {saveError && <p className="error-text">{saveError}</p>}
            </form>
          </div>
        </div>
      )
    }
    </>
  );
};