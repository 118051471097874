import React, { useState, useEffect } from 'react';
import '../style/experience.scss';
import workicon from '../assets/icon/man-with-bag.png';
import bookicon from '../assets/icon/book.png';
import FullPageLoader from '../components/FullPageLoader';
import { experienceRef } from '../database/CollectionRef';
import ErrorPage from '../pages/ErrorPage';

export default function Experience() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [experiences, setExperiences] = useState([]);

  function getExperiences() {
    setLoading(true);
    try {
      experienceRef.orderBy('startDate', 'desc').get().then((item) => {
        const items = item.docs.map((doc) => doc.data());
        setExperiences(items);
        setLoading(false);
      });
    } catch (error) {
      setError('Error during experiences loading.');
    }
  }

  useEffect(() => {
    getExperiences()
  }, []); //array vuoto -> viene eseguito solo al caricamento del componente.

  return (
    <>
      { loading 
        ? <FullPageLoader />
        : error 
        ? <ErrorPage message={error}/>
        : (
          <div className="experiences container">
            <div className="experiences-header">
              <h1 className="section-title">Educ<span>a</span>tion Exper<span>i</span>ence</h1>
            </div>
            {/* Timeline [start] */}
            <div className="experiences-timeline">
              <ul>
                {experiences.map((exp, index) => (
                  <li key={index}>
                    <div className="timeline-item">
                      <div className="title">
                        <div className="icon">
                          {exp.type==='edu'
                            ? <img src={bookicon} alt="img" />
                            : <img src={workicon} alt="img"/>
                          }
                        </div>
                        <h3>{exp.title}</h3>
                      </div>
                      <div className="body">
                        <p>{exp.description}</p>
                      </div>
                    </div>
                    <div className="time">
                      {exp.startDate && <h4>{exp.startDate}</h4>}
                      {exp.endDate 
                        ? <h4>{exp.endDate}</h4>
                        : <h4>Still in progress</h4>
                      }
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* Timeline [end] */}
          </div>
        )
      }
    </>
  );
};