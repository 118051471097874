import React from 'react';

export default function ErrorPage(props) {

  const testoError = "General error."
  const testoCortesia = "Please reload the page. If the problem persists, please contact the site owner (contact at the bottom of the page).";
  const errorMessage = props.message 
    ? props.message +' '+testoCortesia
    : testoError+' '+testoCortesia;

  return (
    <div className="container error-page">
      <h1 className="error-text">{errorMessage}</h1>
    </div>
  );
};