import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import * as config from './config';

const firebaseConfig = config.firebaseConfig;

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;