import React, { useState, useEffect } from 'react';
import { messageRef } from '../database/CollectionRef';
import ErrorPage from '../pages/ErrorPage';
import FullPageLoader from './FullPageLoader';

export default function MessagesEditor(props) {

  // CONTACT
  const [messages, setMessages] = useState([]);

  // FETCHING LOADINGS AND ERRORS
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  function deleteHandler(msg){
    setDeleteError('');
    setDeleteLoading(true);
    try {
      messageRef.doc(msg.id).delete();
    } catch (error) {
      setDeleteError("Delete error: try again.")
    } finally {
      setDeleteLoading(false);
    }    
  }

  function getMessages() {
    setLoading(true);
    try {
      messageRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.date = new Date(data.date).toLocaleDateString('it-IT', {month: 'long', year: 'numeric', day: 'numeric'});
          items.push({id: doc.id, ...data});
        });
        setMessages(items);
      });      
    } catch (error) { 
      setError('Error during messages loading.')
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <>
    { loading 
      ? <FullPageLoader />
      : error 
      ? <ErrorPage message={error}/>
      : (
        <div className="table-container">
          <table>
            <tbody>
              <tr className="table-head">
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
              {messages.map((msg) => (
                <tr key={msg.id}>
                  <td>{msg.date}</td>
                  <td>{msg.name}</td>
                  <td>{msg.email}</td>
                  <td>{msg.message}</td>
                  <td>
                    <p className="action" onClick={()=>deleteHandler(msg)}>elimina</p>
                    {deleteLoading && <p>Loading...</p>}
                    {deleteError && <p className="error-text">{deleteError}</p>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    </>
  );
};