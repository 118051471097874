import React from 'react';
import '../style/navbar.scss';

export default function NavBar() {
  return (
    <nav>
      <div className="brand">
        <a href="#hero">
          <h1><span>F</span>ederico <span>V</span>ulcano</h1>
        </a>
      </div>
      <div className="hamburger">
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
      </div>
      <ul className="nav-links">
        <li><a data-after="Home" href="#hero">Home</a></li>
        <li><a data-after="Experience" href="#experiences">Experience</a></li>
        <li><a data-after="Skills" href="#skills">Skills</a></li>
        <li><a data-after="About" href="#about">About</a></li>
        <li><a data-after="Contact" href="#contact">Contact</a></li>
        <div className="bubble"></div>
      </ul>
    </nav>
  );
};