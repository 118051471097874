import React, { useState } from 'react';
import { messageRef } from '../database/CollectionRef';
import '../style/contact-form.scss';

export default function ContactForm(props) {

  const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  
  // FETCHING LOADINGS RESULTS AND ERRORS
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [saveSuccess, setSaveSuccess] = useState('');

  // EXPERIENCE PROPERTIES
  const [from, setFrom] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function submitHandler(e) {
    e.preventDefault();
    setSaveError(''); 
    setSaveSuccess('');
    setSaveLoading(true);
    try {
      const newMessage = { name: from, email, message, date: Date.now() };
      messageRef.get().then((item) => {
        const items = item.docs.map((doc) => doc.data());
        const messageAlreadyLeaved = items.filter(checkEmail).length !== 0;
        if (!messageAlreadyLeaved) {
          messageRef.doc().set(newMessage);
          emptyForm();
          setSaveSuccess('Thanks for your message!');
        }
        else {
          emptyFromAndEmail();
          setSaveError('You already left a message!'); 
        }
      });
    } catch (error) {
      setSaveError('Error during message saving, please try again.');
    } finally {
      setSaveLoading(false);
    }
  }

  // item e' l'email del messaggio sul quale si sta iterando con il filter
  function checkEmail(item) {
    return item.email === email;
  }

  function resetHandler() {
    emptyForm();
  }

  function emptyFromAndEmail() {
    setFrom('');
    setEmail('');
  }

  function emptyForm() {
    setFrom('');
    setEmail('');
    setMessage('');
    setSaveError('');
    setSaveSuccess('');
  }

  return (
    <>
    {
      <div className="contact-form-container">
        <form className="contact-form" onSubmit={submitHandler} onReset={resetHandler} autoComplete="off">
          
          <h1 className="title">Leave a message</h1>
          
          <div className="form-field">
            <label htmlFor="from">From</label>
            <input type="text" id="from" placeholder="Enter your name" required value={from} onChange={e => setFrom(e.target.value)} />
          </div>
          <div className="form-field">
            <label htmlFor="email">Your email</label>
            <input type="email" pattern={emailPattern} id="email" placeholder="Enter your email" required value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="form-field">
            <label htmlFor="message">Message</label>
            <textarea placeholder="Enter your message" rows={5} required value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          <div className="btn-container">
            <label />
            <button className="primary block" type="submit">Send</button>
            <label />
            <button className="secondary block" type="reset">Cancel</button>
          </div>
          {saveLoading && <p>Loading...</p>}
          {saveError && <p className="error-text">{saveError}</p>}
          {saveSuccess && <p className="success-text">{saveSuccess}</p>}
        </form>
      </div>
    }
    </>
  );
};