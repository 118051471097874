import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminPage from './pages/AdminPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoute from './components/ProtectedRoute';
import * as tracker from './tracking/TrackingService';
import AuthProvider from './utility/auth';
import ForgotPasswordPage from './pages/ForgotPasswordPage';

tracker.initTracking();

function App() {

  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/" component={LandingPage}></Route>
        <Route path="/login" component={LoginPage}></Route>
        <Route path="/forgot-password" component={ForgotPasswordPage}></Route>
        <ProtectedRoute path="/admin" component={AdminPage}></ProtectedRoute>
        <Route path="*" component={NotFoundPage}></Route>
      </Switch>
    </AuthProvider>
  );

}

export default App;
