import React from 'react';

export default function EmailLink(props) {
  const mailto = `mailto:${props.email}`
  return (
    <div className="contact-info">
      <h1>{props.title}</h1>
      <a href={mailto}>Click here</a>
    </div>
  );
};