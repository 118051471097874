import React, { useState, useEffect } from 'react';
import '../style/contact.scss';
import phoneicon from '../assets/icon/phone.png';
import emailicon from '../assets/icon/email.png';
import fbicon from '../assets/icon/facebook.png';
import igicon from '../assets/icon/instagram.png';
import lkicon from '../assets/icon/linkedin.png';
import { contactRef } from '../database/CollectionRef';
import FullPageLoader from './FullPageLoader';
import ErrorPage from '../pages/ErrorPage';
import EmailLink from './EmailLink';
import SocialLink from './SocialLink';
import ContactForm from './ContactForm';

export default function Contact() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [contacts, setContacts] = useState([]);

  function getContacts() {
    setLoading(true);
    try {
      contactRef.get().then((item) => {
        const items = item.docs.map((doc) => doc.data());
        setContacts(items);
        setLoading(false);
      });
    } catch (error) {
      setError('Error during contacts loading.');
    }
  }

  useEffect(() => {
    getContacts()
  }, []); //array vuoto -> viene eseguito solo al caricamento del componente.

  return (
    <>
      { loading 
        ? <FullPageLoader />
        : error 
        ? <ErrorPage message={error}/>
        : (
          <div className="contact container">
            <div>
              <h1 className="section-title">Contact <span>info</span></h1>
            </div>
            <div className="boxes">
              <ContactForm />
              <div className="contact-items">
                {
                  contacts.map((cntc, index) => (
                    <div className="contact-item" key={index}>
                      <div className="icon">
                        {cntc.type==='phone' && <img src={phoneicon} alt="img" />}
                        {cntc.type==='email' && <img src={emailicon} alt="img" />}
                        {cntc.type==='ig' && <img src={igicon} alt="img" />}
                        {cntc.type==='fb' && <img src={fbicon} alt="img" />}
                        {cntc.type==='lk' && <img src={lkicon} alt="img" />}
                      </div>
                      { cntc.type==='email' && <EmailLink title={cntc.title} email={cntc.contact} /> }
                      { cntc.type==='phone' && <div className="contact-info"><h1>{cntc.title}</h1><p>{cntc.contact}</p></div> }
                      { (cntc.type==='fb' || cntc.type==='lk' || cntc.type==='ig') && <SocialLink social={cntc.title} link={cntc.contact}/>}                      
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};