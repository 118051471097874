import React, { useEffect } from 'react';
import * as animations from '../animations/animations';
import NavBarAdmin from '../components/NavBarAdmin';
import ExperienceEditor from '../components/ExperiencesEditor';
import SkillsEditor from '../components/SkillsEditor';
import ContactEditor from '../components/ContactEditor';
import AboutEditor from '../components/AboutEditor';
import MessagesEditor from '../components/MessagesEditor';

export default function AdminPage(props) {

  useEffect(() => {
    animations.startAnimation();
  }, []);

  return (
    <div className="admin">
      <div>
        <header>
          <NavBarAdmin />
        </header>
        <section id="top">
          <div className="container column">
            <div>
              <h1 className="section-title">Admin <span>dash</span></h1>
            </div>
            <div>
              <a href="#experience" type="button" className="cta">Experiences</a>
              <a href="#skill" type="button" className="cta">Skills</a>
              <a href="#about" type="button" className="cta">About</a>
              <a href="#contact" type="button" className="cta">Contact</a>
              <a href="#messages" type="button" className="cta">Messages</a>
            </div>
          </div>
        </section>
        <section id="experience">
          <div className="container column">
            <h2>Experiences</h2>
            <div className="table-form-container">
              <ExperienceEditor />
            </div>
          </div>
        </section>
        <section id="skill">
          <div className="container column">
            <h2>Skills</h2>
            <div className="table-form-container">
              <SkillsEditor />
            </div>
          </div>
        </section>
        <section id="about">
          <div className="container column">
            <h2>About</h2>
            <div className="table-form-container">
              <AboutEditor />
            </div>
          </div>
        </section>
        <section id="contact">
          <div className="container column">
            <h2>Contact</h2>
            <div className="table-form-container">
              <ContactEditor />
            </div>
          </div>
        </section>
        <section id="messages">
          <div className="container column">
            <h2>Messages</h2>
            <MessagesEditor />
          </div>
        </section>
      </div>
    </div>
  );
};